







































































import api from "@/api/index"; //ABP API接口
import {Component, Vue} from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView/index.vue";
import {DataDictionaryDtoPagedResultDto, DataDictionaryDto, TrainStatus} from "@/api/appService";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "TrainList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag
  }
})
export default class TrainList extends Vue {
  typeList:DataDictionaryDto[]=[];
  queryForm: any = {
    title: undefined,
    trainTypeId:undefined,
    status: TrainStatus.Published,
    startDate:undefined,
    endDate:undefined
  }

  created(){
    this.fetchDataDictionary()
  }

  // 获取表数据
  async fetchData(params: any) {
    params.status=TrainStatus.Published;
    return await api.train.getAll(params);
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "TrainType", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.typeList = res.items!;
      });
  }

  // 报名签到明细页
  handleSummary(item: any) {
    this.$router.push({name: 'trainApplySummary', params: {'id': item.id!.toString()}})
  }
}
